import logo from "../src/images/grupaaflogo.png";
import "./App.css";
import { Link } from "react-router-dom";
import React from "react";

function App() {
  return (
    <div className="App">
      <div className="button-container">
        <div>
          <img src={logo} alt="GAF logo" />
        </div>
        <div className="buttons-wrapper">
          <Link to="/gaf">
            <button className="main-button">GrupaAF</button>
          </Link>
          <Link to="/fundacja">
            <button className="main-button">Fundacja Biznes Bez Barier</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
