import React, { useState, useEffect } from "react";
import "./GafSignature.styles.css";
import logo from "../../images/grupaaflogo.png";
import { Link } from "react-router-dom";
import StopkaGaf from "../stopkaHTML/stopkaGaf.component";

const GafSignature = () => {
  useEffect(() => {
    document.title = "Generator stopek GrupaAF";
  }, []);
  const [data, setData] = useState({
    imie: "",
    nazwisko: "",
    stanowisko: "",
    telefon: "",
    email: "",
    img: "",
  });
  const [isSubmited, setIsSubmited] = useState(false);
  const [img, setImg] = useState("");

  const handleFileLoad = (e) => {
    setImg(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("picture", img);
    formData.append("fullname", data.imie + data.nazwisko);

    const headers = new Headers({
      "Content-Type": `multipart/form-data; boundary="--WebKitFormBoundary"`,
      "Content-Disposition": `attachment; name="picture"; filename='picture';`,
    });

    const url = "https://api.cv.biznesbezbarier.org/footer/picture/";
    fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    })
      .then((response) => response.json())
      .then((result) => {
        setData({ ...data, img: result.picture });
      })
      .then(() => {
        setTimeout(() => {
          setIsSubmited(true);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className="gaf-container">
      {isSubmited ? (
        <div>
          <div className="img-container">
            <Link to="/">
              <img src={logo} alt="GAF logo" />
            </Link>
          </div>
          <StopkaGaf
            imie={data.imie}
            nazwisko={data.nazwisko}
            stanowisko={data.stanowisko}
            tel={data.telefon}
            email={data.email}
            img={data.img}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="gaf-form">
          <div className="img-container">
            <Link to="/">
              <img src={logo} alt="GAF logo" />
            </Link>
          </div>
          <label htmlFor="Imie">
            Imię
            <input
              onChange={handleChange}
              className="gaf-input"
              type="text"
              placeholder="Imię"
              id="Imie"
              name="imie"
              autoComplete="given-name"
              required
            />
          </label>
          <label htmlFor="Nazwisko">
            Nazwisko
            <input
              onChange={handleChange}
              className="gaf-input"
              type="text"
              placeholder="Nazwisko"
              id="Nazwisko"
              name="nazwisko"
              autoComplete="family-name"
              required
            />
          </label>
          <label htmlFor="Stanowisko">
            Stanowisko
            <input
              onChange={handleChange}
              className="gaf-input"
              type="text"
              placeholder="Stanowisko"
              name="stanowisko"
              id="Stanowisko"
              required
            />
          </label>
          <label htmlFor="Telefon">
            Telefon np. 123-456-789
            <input
              onChange={handleChange}
              className="gaf-input"
              type="tel"
              placeholder="Telefon"
              id="Telefon"
              autoComplete="tel"
              name="telefon"
              pattern="(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)"
              required
            />
          </label>
          <label htmlFor="Email">
            Email
            <input
              onChange={handleChange}
              className="gaf-input"
              type="email"
              placeholder="Email"
              id="Email"
              autoComplete="email"
              name="email"
              required
            />
          </label>
          <label htmlFor="Photo">
            Zdjęcie
            <input className="gaf-file" id="Photo" required type="file" size="10000000" onChange={handleFileLoad} />
          </label>
          <label htmlFor="submit">
            <input className="main-button" type="submit" id="submit" value="Generuj Stopkę" />
          </label>
        </form>
      )}
    </div>
  );
};

export default GafSignature;
