import React, { useState } from "react";
import "./stopkaGaf.styles.css";

const StopkaFun = ({ imie, nazwisko, stanowisko, email, tel, img }) => {
  const [stopkaText] = useState(`<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8" />
    <link
      href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap"
      rel="stylesheet"
    />
    <style type="text/css">
      * {
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
      body {
        font-family: "Source Sans Pro", sans-serif;
      }
      .bold {
        font-weight: bold;
      }
      .signature {
      }
      .signature--header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        margin-bottom: 10px;
      }
      .signature--header__text {
        margin-left: 15px;
      }
      .signature--header__text .full-name {
        font-size: 19px;
      }
      .signature--header__text .post {
        font-size: 15px;
      }
      .signature--links a {
        display: block;
        font-size: 16px;
        color: #202020 !important;
        text-decoration: none !important;
        width: 100% !important;
      }
      .signature--links a.bold {
        font-size: 18px;
        margin: 5px 0 15px 0;
        display: block;
      }
    </style>
  </head>
  <body
    style="font-family: 'Source Sans Pro', sans-serif; margin-left: -25px;"
  >
    <div class="signature" style="padding-left: 30px;">
      <div
        style="
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          margin-bottom: 10px;
          align-items: flex-end;
        "
        class="signature--header"
      >
        <img
          src="${img}"
          alt="${imie} ${nazwisko}"
        />
        <div style="margin-left: 15px;" class="signature--header__text">
          <p style="font-size: 19px; margin: 0;" class="bold full-name">
            ${imie} ${nazwisko}
          </p>
          <p style="font-size: 15px; margin: 0;" class="post">
            ${stanowisko}
          </p>
        </div>
      </div>
      <div class="signature--links">
        <a
          style="
            text-decoration: none;
            display: block;
            font-size: 16px;
            color: #202020 !important;
            text-decoration: none !important;
            width: 100% !important;
            width: 100%;
          "
          href="tel:+48 ${tel}"
          title=""
          >(+48) ${tel}</a
        >
        <a
          style="
            text-decoration: none;
            display: block;
            font-size: 16px;
            color: #202020 !important;
            text-decoration: none !important;
            width: 100% !important;
            width: 100%;
          "
          style="
            text-decoration: none;
            display: block;
            width: 100%;
            color: rgb(32, 32, 32);
          "
          href="mailto:${email}"
          title=""
          >${email}</a
        >
        <a
          style="font-size: 18px; margin: 5px 0 15px 0; display: block;"
          class="bold"
          href="https://www.biznesbezbarier.org"
          title="biznesbezbarier.org"
          ><b>BiznesBezBarier</b>.org</a
        >
      </div>
      <img
        src="http://ftp.grupaaf.pl/emails/2019/bbb/Logo_BBB_PL.png"
        alt="Logo Biznes Bez Barier"
      />
    </div>
  </body>
</html>
</body>
</html>
  `);

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(e.target.value);
    setIsCopied(true);
  };

  return (
    <div className="signature-html-container">
      
      <button className="main-button" onClick={copyToClipboard} value={stopkaText}>
        Skopiuj
      </button>

      {isCopied && <span>Skopiowano!</span>}
      <textarea id="stopka" disabled value={stopkaText} />
    </div>
  );
};

export default StopkaFun;
