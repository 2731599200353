import React, { useState } from "react";
import "./stopkaGaf.styles.css";

const StopkaGaf = ({ imie, nazwisko, stanowisko, email, tel, img }) => {
  const [stopkaText] = useState(`<html>
  <head>
  <meta http-equiv="content-type" content="text/html; charset=utf-8" />
  </head>
  
  <body>
  <table
  style="
    font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
    font-size: 15px;
    font-weight: normal;
    margin: 0 0 10px;
    padding: 5px;
  "
  >
  <tbody>
    <tr>
      <td>
        <table
          style="
            font-size: 12px;
            font-weight: normal;
            color: #585857;
            padding: 10px;
            background: #f9f9f9;
            border: 1px solid #eee;
          "
        >
          <tbody>
            <tr>
              <td
                width="80"
                valign="top"
                style="text-align: center; padding: 0 18px 0 0;"
              >
                <img
                  src="${img}"
                  alt="${imie} ${nazwisko}, ${stanowisko}"
                  border="0"
                  width="70"
                  height="70"
                  style="max-width: 100%;"
                />
                <p style="margin: 20px 0 0;">
                  <a href="https://grupaaf.pl" target="_blank"
                    ><img
                      src="https://ftp.grupaaf.pl/emails/res/logo2017.png"
                      width="80"
                      alt="Projektowanie stron internetowych - Grupa AF"
                      style="max-width: 100%; height: auto;"
                      border="0"
                  /></a>
                </p>
              </td>
              <td
                style="padding: 0 0 0 20px; border-left: solid 3px #f3ce12;"
                valign="top"
              >
                <h2
                  style="
                    font-size: 17px;
                    margin: 0 0 0;
                    text-transform: uppercase;
                    font-weight: bold;
                    padding: 0;
                    color: #f3ce12;
                  "
                >
                ${imie} ${nazwisko}
                </h2>
                <span style="font-weight: normal;"
                  >${stanowisko}</span
                >
                <div style="margin: 15px 0 2.5px;">
                  <p style="margin: 0 0 2.5px;">
                    <span
                      style="
                        min-width: 50px;
                        display: inline-block;
                        color: #afafaf;
                      "
                      >e-mail:</span
                    >
                    <a
                      href="mailto:${email}"
                      style="
                        text-decoration: none;
                        color: #585857;
                        font-weight: bold;
                        padding: 2.5 5px;
                        display: inline-block;
                      "
                      > ${email}</a
                    >
                  </p>
                  <p style="margin: 0 0 2.5px;">
                    <span
                      style="
                        min-width: 50px;
                        display: inline-block;
                        color: #afafaf;
                      "
                      >phone:</span
                    >
                    <a
                      style="
                        text-decoration: none;
                        color: #585857;
                        font-weight: bold;
                        padding: 2.5 5px;
                        display: inline-block;
                      "
                      href="tel:(+48) ${tel}"
                      >(+48) ${tel}</a
                    >
                  </p>
                  <p style="margin: 20px 0 2.5px;">
                    <span
                      style="
                        min-width: 50px;
                        display: inline-block;
                        color: #afafaf;
                      "
                      >offices:</span
                    >
                    <a
                      href="https://goo.gl/maps/29KqLd5nojB2"
                      target="_blank"
                      style="
                        text-decoration: none;
                        color: #585857;
                        font-weight: bold;
                        padding: 2.5 5px;
                        display: inline-block;
                        margin: 0 2.5px;
                      "
                      >Wrocław</a
                    >
                    <span
                      style="
                        color: #f3ce12;
                        font-weight: bold;
                        font-size: 15px;
                      "
                      >/</span
                    >
                    <a
                      href="https://goo.gl/maps/zCDApVH41Wp"
                      target="_blank"
                      style="
                        text-decoration: none;
                        color: #585857;
                        font-weight: bold;
                        padding: 2.5 5px;
                        display: inline-block;
                        margin: 0 2.5px;
                      "
                      >Warszawa</a
                    >
                    <span
                      style="
                        color: #f3ce12;
                        font-weight: bold;
                        font-size: 15px;
                      "
                      >/</span
                    >
                    <a
                      href="https://goo.gl/maps/7LdQixQH2SE2"
                      target="_blank"
                      style="
                        text-decoration: none;
                        color: #585857;
                        font-weight: bold;
                        padding: 2.5 5px;
                        display: inline-block;
                        margin: 0 2.5px;
                      "
                      >Legnica</a
                    >
                  </p>
                  <p style="line-height: normal; margin: 0;">
                    <span
                      style="
                        min-width: 50px;
                        display: inline-block;
                        color: #afafaf;
                      "
                      >www:</span
                    >
                    <a
                      href="https://grupaaf.pl/"
                      target="_blank"
                      style="
                        text-decoration: none;
                        color: #585857;
                        font-weight: bold;
                        padding: 2.5 5px;
                        display: inline-block;
                      "
                      >https://GrupaAF.pl</a
                    >
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style="
            font-size: 15px;
            font-weight: 400;
            color: #585857;
            margin: 25px auto 15px;
          "
        >
          <tbody>
            <tr>
              <td>
                <div>
                  <p
                    style="
                      text-transform: uppercase;
                      font-weight: 700;
                      margin: 0;
                    "
                  >
                    They trusted us:
                  </p>
                  <a
                    href="https://grupaaf.pl"
                    target="_blank"
                    style="display: block; text-align: center;"
                    ><img
                      src="http://ftp.grupaaf.pl/emails/res/clients1.jpg"
                      border="0"
                      style="max-width: 100%; margin: 5px 0 0;" /><img
                      src="http://ftp.grupaaf.pl/emails/res/clients2.jpg"
                      border="0"
                      style="max-width: 100%; margin: 5px 0 0;"
                  /></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table style="max-width: 450px;">
          <tbody>
            <tr>
              <td>
                <p
                  style="font-size: 10.5px; margin: 10px 0; color: #afafaf;"
                >
                  Treść niniejszej wiadomości zawiera informacje o
                  charakterze poufnym. Jej wykorzystanie przez osobę
                  nieuprawnioną jest niedozwolone. Jeśli nie są Państwo
                  adresatem przesyłki prosimy o bezzwłoczne skontaktowanie
                  się z nadawcą.
                </p>
                <p
                  style="font-size: 10.5px; margin: 10px 0; color: #afafaf;"
                >
                  The content of this e-mail contains confidential
                  information. Any use of this content by an unauthorized
                  person is prohibited. If you are not the intended
                  recipient please do not hesitate to contact the sender.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
  </table>
  </body>
  </html>
  `);

  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(e.target.value);
    setIsCopied(true);
  };
  return (
    <div className="signature-html-container">
      <button className="main-button" onClick={copyToClipboard} value={stopkaText}>
        Skopiuj
      </button>
      {isCopied && <span>Skopiowano!</span>}
      <textarea id="stopka" disabled value={stopkaText} />
    </div>
  );
};

export default StopkaGaf;
